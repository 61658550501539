body {
  font-family: "Open Sans", sans-serif;
  background: #000 url("/img/bg.jpg") no-repeat center top;
}

.homepage {
  margin: 100px auto 0;
  color: #fff;
  text-align: center;
}

.homepage h1 {
  font-size: 50px;
}

.contact-form {
  padding: 2rem;
  margin-bottom: 2rem;
  color: #333;
  text-align: left;
  background-color: #f9f9f9;
  border-color: #778490;
  border-style: solid;
  border-width: .125rem;
  border-radius: 8px;
}

.contact-form .text-help {
  display: block;
  margin-top: 2px;
}

.contact-form ::-webkit-input-placeholder {
  color: #ccc;
}

.contact-form ::-moz-placeholder {
  color: #ccc;
}

.contact-form :-ms-input-placeholder {
  color: #ccc;
}

.contact-form ::-ms-input-placeholder {
  color: #ccc;
}

.contact-form ::placeholder {
  color: #ccc;
}

.form-group.required > label::after {
  margin-left: 2px;
  color: #f00;
  content: "*";
}

.upload-dropzone {
  position: relative;
  overflow: hidden;
  font-size: .9rem;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.fortel-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3rem;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}

.fortel-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3rem;
  padding: .8rem .75rem;
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fortel-file-label i {
  font-size: 1rem;
}

.text-userfiles {
  display: block;
  margin-bottom: .5rem;
}

.invalid-recaptcha {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

#files {
  margin-top: .5rem;
  border-top: 1px solid #ced5dc;
  border-bottom: 1px solid #fff;
}

#files .template-upload {
  padding: .4rem;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ced5dc;
}

#files .template-upload .name {
  color: #333;
}

#files .template-upload .size {
  font-size: .8rem;
  color: #7f7f7f;
}

#files .template-upload .remove,
#files .template-upload .cancel {
  display: inline-block;
  width: 15px;
  height: 12px;
  cursor: pointer;
  background: url("/img/icons.png") no-repeat;
  background-position-y: -13px;
}

#files .template-upload .icon.success {
  display: inline-block;
  width: 15px;
  height: 12px;
  background: url("/img/icons.png") no-repeat;
}

#files .template-upload.error .name {
  color: #dc3545;
}

#files .template-upload.error .progress > .progress-bar {
  background-color: #dc3545 !important;
}

#files .template-upload.error .message {
  color: #dc3545;
}

.success-form {
  padding: 2rem;
  margin: 100px auto;
  color: #333;
  text-align: left;
  background-color: #f9f9f9;
  border-color: #778490;
  border-style: solid;
  border-width: .125rem;
  border-radius: 8px;
}

.success-form .alert {
  display: inline-block;
  max-width: 400px;
  margin-bottom: 3rem;
}
/*# sourceMappingURL=main.css.map */